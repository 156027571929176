import React from 'react'
import TopHeader from './top/TopHeader'
import CenterHead from './center/CenterHead'
import Header from './bottom/Header'

const MainHeader = () => {
    return (
        <div>
            <TopHeader />
            <CenterHead />
            <Header />
        </div>
    )
}

export default MainHeader