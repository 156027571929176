
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
function PaymentModes(props) {


    const data = [
        {
            id: 0, title: "Bank Account No.", title1: "0636010100257320",
        },
        {
            id: 1, title: "Type of Bank Account", title1: "Savings",
        },
        {
            id: 2, title: "Name of The Bank", title1: "Punjab National Bank",
        },
        {
            id: 3, title: "Branch Name", title1: "Lado Sarai,New delhi",
        },
        {
            id: 4, title: "MICR Code", title1: "110024476",
        },
        {
            id: 5, title: "IFSC Code", title1: "PUNB0498000",
        },
        {
            id: 6, title: "SWIFT Code", title1: "PUNBINBBISB",
        },
    ]

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Payment Modes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Registration Guidelines</h4>
                <div>
                    <ol className='mainlist mainlist-v'>
                        <li>Registration is open to the all the healthcare persons involved in treatment, diagnosis and research in HIV medicine and allied fields.
                        </li>
                        <li>
                            The last date of the registration is 20th December 2023.
                        </li>
                        <li>
                            The Registration Fee is Rs 3000/-(Rupees Three thousands only)
                        </li>
                        <li>
                            The Registration fees can be paid in the following account through NEFT/Net Banking.
                            <div>
                                <h5 className='mt-3 mb-3'>Details as follows:</h5>
                                <div className=''>
                                    <Table striped className='table-responsive table-bordered' id='register-table '>
                                        <tbody style={{ overflow: "scroll" }}>
                                            {data.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.title}</td>
                                                        <td>{item.title1}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </li>
                        <li >
                            5 .After paying Registration fee please send the Payment details in following formats to Email Id:  <Link to="mailto:stophiv2024@gmail.com" style={{ color: "blue", textDecoration: "none" }}>stophiv2024@gmail.com</Link>
                            <div className='mt-2 row' style={{ border: "1px solid black", width: "auto" }}>
                                <div className="col-lg-12" style={{ border: "1px solid black", padding: "4px 13px 4px 13px" }}>
                                    Name
                                </div>
                                <div className="col-lg-12" style={{ border: "1px solid black", padding: "4px 13px 4px 13px" }}>
                                    Designation
                                </div>
                                <div className="col-lg-12" style={{ border: "1px solid black", padding: "4px 13px 4px 13px" }}>
                                    Mobile No.
                                </div >
                                <div className="col-lg-12" style={{ border: "1px solid black", padding: "4px 13px 4px 13px" }}>
                                    Email Address
                                </div>
                                <div className="col-lg-12" style={{ border: "1px solid black", padding: "4px 13px 4px 13px" }}>
                                    Payment reference No./UTR No.
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>

                <div>
                    <p className='mainlist-v'>The Details can also be submitted through website (<Link to="https://www.stophiv2024.com/register" style={{ color: "blue", textDecoration: "none" }}>https://www.stophiv2024.com/register</Link>) on Registration
                        Tab/register</p>
                    <h6 className='mainlist-v'>
                        Note: Those whose abstract will be selected for the presentation may apply for the sponsorship of registration fee. Other healthcare professionals may also apply for sponsorship of registration fees through Email: <Link to="mailto:stophiv2024@gmail.com" style={{ color: "blue", textDecoration: "none" }}>stophiv2024@gmail.com</Link>
                    </h6>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal >
    );
}

export default PaymentModes;


// function App() {
//     const [modalShow, setModalShow] = React.useState(false);

//     return (
//         <>
//             <Button variant="primary" onClick={() => setModalShow(true)}>
//                 Launch vertically centered modal
//             </Button>

//             <MyVerticallyCenteredModal
//                 show={modalShow}
//                 onHide={() => setModalShow(false)}
//             />
//         </>
//     );
// }

// render(<App />);