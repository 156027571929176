
import React from 'react';
import Image from 'react-bootstrap/Image';
import img1 from '../../img/conference.png';
import braucher from '../../img/final.pdf'
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
const ImgDiv = () => {
    return (
        <div className='imgDiv'>
            <Image src={img1} className='img' fluid />
            <Card className='brouchercard'>
                <Card.Body className='brouchercard-body mb-1' >
                    <Card.Title className=''>Conference Date</Card.Title>
                    <Card.Subtitle className='mt-1'>13-14 January 2024</Card.Subtitle>
                    <Button className='mt-3 broucher-btn' href={braucher} download>Conference Brochure</Button>
                </Card.Body>
            </Card>
        </div>

    )
}

export default ImgDiv;