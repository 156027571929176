const scientific = [{
    id: 0,
    titile: "Dr. Norman Markowitz– Chairperson",
}, {
    id: 1,
    titile: "Dr. Rajnish Gupta- Co Chairperson",

},
{
    id: 2,
    titile: "Dr. Lokender Kumar",

}, {
    id: 3,
    titile: "Dr. J. K. Saini",
}, {
    id: 4,
    titile: "Dr. Amit Sharma",
},
{
    id: 5,
    titile: "Mr. Rajeev Senger",
}]


export default scientific;