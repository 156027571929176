
import './App.css';
// import Header from './components/header/Header';
import "bootstrap/dist/css/bootstrap.min.css"
import MainHeader from './components/header/MainHeader';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/aboutus/About';
import Abstract from './pages/abstract/Abstract';
import ContactUs from './pages/contactus/ContactUs';
import Footer from './components/footer/Footer';
import Committee from './pages/committee/Committee';
import 'react-toastify/dist/ReactToastify.css';
import Register from './pages/registration/Registration';
import Scientific from './pages/scientific/Scientific';
import PagenotFound from './components/PagenotFound';


function App() {
  return (
    <BrowserRouter>
      <MainHeader />
      <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/abstract' element={<Abstract />}></Route>
        {/* <Route path='/register' element={<Register />}></Route> */}
        <Route path='/committee' element={<Committee />}></Route>
        <Route path='/scientific' element={<Scientific />}> </Route>
        <Route path='/contactus' element={<ContactUs />}></Route>

        <Route path='*' element={<PagenotFound />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
