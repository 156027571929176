import React from 'react'
import './style.css'
import { Col, Container, Row } from 'react-bootstrap'
import logo1 from '../../img/logo1.png'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
const Footer = () => {
    return (
        <Container className='mainContainer' fluid>
            <Container>

                <Row className=''>
                    <Col className="col-lg-4 col-md-6 col-12 mt-5">
                        <img className='mb-3' src={logo1} alt=""></img>
                        <h5>STOP HIV 2024</h5>
                        {/* <h5>NAPCON 2023 Delhi</h5> */}
                        <h5>Send Us Feedback</h5>

                    </Col>

                    <Col className="col-lg-4 col-md-6 col-12 mt-5">
                        <h5>Conference Secretariat</h5>
                        <h6 className='col-h6'>Dr. Upasna Agarwal(Organizing Secretary)</h6>
                        <p className='col-p'>
                            Mobile No.-+91 8800788930
                        </p>
                        <p className='col-p'>
                            Email-stophiv2024@gmail.com
                        </p>
                        <h6 className='col-h6'>Dr. Ashish Ranjan( Co-organizing secretary)</h6>
                        <p className='col-p'>
                            Mobile No.- 8826750834
                        </p>
                    </Col>
                    <Col className="col-lg-4 col-md-6 col-12 mt-5">
                        <h5>
                            About Conference
                        </h5>
                        <h5 className='col-h6 footer-date'> Stop HIV 2024</h5>
                        <div className='col-h6 blink'><h2 className='blink-content'>13-14 January 2024</h2></div>
                        <h6 className='col-h6'>New Delhi</h6>
                    </Col>
                </Row>
                <hr className='text-light' />

                <div className='copyright-footer'>
                    <div><p className='text-light'>&copy;2023 All Rights Reserved</p></div>
                    <div>
                        <TwitterIcon className='footer-icon' />
                        <FacebookIcon className='footer-icon' />
                        <YouTubeIcon className='footer-icon' />
                        <InstagramIcon className='footer-icon' />

                    </div>
                </div>
            </Container>
        </Container>
    )
}

export default Footer