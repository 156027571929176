import React from 'react'
import commingsoon from '../../img/ScientificProgram.pdf'
import './scientific.css'
const Scientific = () => {
    return (
        <div className='container-fluid'>
            <div className='container commingmainimg'>
                <a className='img-fluid' src={commingsoon} alt='' />
            </div>
        </div>
    )
}

export default Scientific