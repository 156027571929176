const advisary = [{
    id: 0,
    titile: "Dr B.B.Rewari",

}, {
    id: 1,
    titile: "Dr.A.K.Puri",
},
{
    id: 2,
    titile: "Dr.Chinmoyee Das",

}, {
    id: 3,
    titile: "Dr.S.K.Guha",

}, {
    id: 4,
    titile: "Dr.S.K.Munjal",
},
{
    id: 5,
    titile: "Dr.Manpreet Bhalla",

}]


export default advisary;


