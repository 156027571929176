export const instructions = [
    {
        id: 0,
        title: `All abstracts must be submitted in English Times new Roman with a font size of 12 and double spacing.`
    },
    {
        id: 1,
        title: " The material in the abstract must be original and not previously published."
    },
    {
        id: 2,
        title: "Original research and case reports/ series can be submitted as abstract."
    },
    {
        id: 3,
        title: "The abstract should be submitted in the following format:"
    },
]