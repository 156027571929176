import React from 'react';
import { gudelines } from './gudeline';
import { instructions } from './instructions';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { caseReport, originalresearch } from './awards';
import './abstract.css'
import Example from './AbstractModal';


const data = gudelines.map((item) => {
    return <li className='li-item' key={item.id}>{item.title}</li>

})

const data1 = instructions.map((item) => {
    return <li className='li-item' key={item.id}>{item.title}</li>

})

const data3 = originalresearch.map((item) => {
    return <li className='li-item' key={item.id}>{item.name}</li>
})
const data4 = caseReport.map((item) => {
    return <li className='li-item' key={item.id}>{item.name}</li>
})

const Abstract = () => {


    return (
        <>
            <Container className='main-container' fluid >

                <Container className='pt-2 pb-3'>
                    <Card className='' style={{ borderRadius: "25px" }}>
                        <Card.Body>

                            <Row className='mt-3 mb-2'>
                                <Col>
                                    <h1 className="text-center">Abstract Guidelines</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ul>
                                        {data}
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <h3 className='mt-3 mb-3' style={{ marginRight: "30px", marginLeft: "30px" }}>
                                    Abstract preparation instructions:
                                </h3>
                            </Row>
                            <Row>
                                <Col>

                                    <ul>
                                        {data1}
                                    </ul>
                                </Col>
                            </Row>
                            <section className='guidelineblocks'>
                                <Row>
                                    <h4>1. Format of original research</h4>
                                </Row>
                                <Row>
                                    <Col>

                                        <ul>
                                            {data3}
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                            <section className='guidelineblocks'>
                                <Row>
                                    <h4>2. Format of Case Report/ Series</h4>
                                </Row>
                                <Row>
                                    <Col>
                                        <ul>
                                            {data4}
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                            <Row className='mb-5'>
                                <Col className='text-center'>
                                    <Example />
                                </Col>
                            </Row>
                        </Card.Body>

                    </Card>
                </Container>

            </Container>
        </>
    )
}

export default Abstract