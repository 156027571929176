export const awards = [{
    id: 0,
    title: ' All abstracts must be submitted in English- Times New Roman, with a font size of 12 and double spacing.To ensure blinded review- no identifying features such as names of hospitals, medical colleges, clinics or places may be listed in the title or text of the abstract.',
}, {
    id: 1,
    title: ' Do not include the names of authors and their affiliations in the abstract body. There will be provision of entering author details and their affiliation(s) in the submission platform.',
}, {

    id: 2,
    title: ' The material in the abstract must be original and not previously published.',
}, {
    id: 3,
    title: ' A presenting author has to submit the abstract himself/ herself.',
}, {
    id: 4,
    title: ' A presenting author can submit a maximum of 2 abstracts for consideration for NAPCON 2023, New Delhi.',
}]




export const originalresearch = [
    {
        id: 0,
        name: "Title"
    },
    {
        id: 1,
        name: "Author Names"
    },
    {
        id: 2,
        name: "Author Affiliation"
    },
    {
        id: 3,
        name: "Background"
    },
    {
        id: 4,
        name: "Aims/ Objectives"
    },
    {
        id: 5,
        name: "Methodology"
    },
    {
        id: 6,
        name: "Results"
    },
    {
        id: 7,
        name: "Conclusions"
    }]

export const caseReport = [
    {
        id: 0,
        name: "Title"
    },
    {
        id: 1,
        name: "Author Names"
    },
    {
        id: 2,
        name: "Author Affiliation"
    },
    {
        id: 3,
        name: "Background/ Introduction"
    },
    {
        id: 4,
        name: "Clinical Findings"
    },
    {
        id: 5,
        name: "Diagnostic Evaluation"
    },
    {
        id: 6,
        name: "Treatment/ Follow Ups"
    },
    {
        id: 7,
        name: "Conclusions"
    }]