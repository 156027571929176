import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

import monument1 from '../../img/monument1.png'
import monument2 from '../../img/monument2.png'
import monument3 from '../../img/SwaminarayanAkshardham.jpg'

const imgbox = [
    {
        id: 0,
        img: monument1,
        name: "Qutub Minar"
    },
    {
        id: 1,
        img: monument2,
        name: "Humayuns Tomb"
    },
    {
        id: 2,
        img: monument3,
        name: "Akshardham"
    }
]

const LocalAttractions = () => {
    return (
        <Container  className=''>
           
                <Row>
                    <Col className="text-center mt-5 mb-3 "><h1>Local Attractions</h1></Col>
                </Row>
                <Row className='monuments-row'>
                    {imgbox.map((item) => {
                        return <Col className='monuments-col col-lg-4 col-md-6 col-12' key={item.id} >
                            <Card className='monument-cards' >
                                <Card.Body>
                                    <Card.Img className='monuments-img' src={item.img} />
                                    <Card.ImgOverlay className='monuments-overlay'>
                                        <Card.Text className='monuments-card-text'>{item.name}</Card.Text>
                                    </Card.ImgOverlay>

                                </Card.Body >
                            </Card>
                        </Col>
                    })}

                </Row>
            </Container>
        
    )
}

export default LocalAttractions