import React from 'react'
import './home.css'
import Card from 'react-bootstrap/Card';
import { Container } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import docimg from '../../img/Dr. RK Dewan.JPG'
import docimg1 from '../../img/Marcus Zervos MD.jpg'
import AboutVanue from './AboutVanue';
import ImgDiv from './ImgDiv';
import LocalAttractions from './LocalAttractions';
import Runner from './Runner';

const Home = () => {
    return (
        <div>
            <Runner />
            <ImgDiv />
            <div className='maindiv'>
                <div className=' contentdiv  align-center'>
                    <Container className='p-3' >
                        <Row className='main-row tip'>
                            <div className='dr-card1'>   </div>
                            <div className=' col-lg-12  '>

                                <Card className='mt-2'>
                                    <Card.Body className='home-card-body' >

                                        <Row>
                                            <Col className='mb-2'>
                                                <Card.Title>Welcome to the STOP HIV 2024, New Delhi
                                                </Card.Title>
                                            </Col>
                                        </Row>
                                        <Row className='colreverse'>
                                            <div className='col-md-8'>
                                                <Card.Subtitle className="mb-2 text-muted ">Dear Colleagues and Friends,</Card.Subtitle>
                                                <Card.Text className='text-justify'>
                                                    It gives me immense pleasure to invite you to this international conference in HIV
                                                    Medicine. This conference is a collaborative effort of National Institute of
                                                    Tuberculosis and Respiratory Diseases, New Delhi and the Division of Infectious
                                                    Diseases, Henry Ford Hospital, Detroit (US).
                                                </Card.Text>
                                                <Card.Text className='text-justify'>
                                                    STOP HIV 2024 will be held on 13-14 January 2024, at the Indian Habitat Centre,
                                                    New Delhi. In this exciting event, we will explore, discuss, and collaborate on a wide
                                                    range of topics, ranging from updates in HIV therapeutics, HIV associated TB, public health
                                                    approaches to HIV, paediatric TB to recent advances in prevention and latest HIV research. We have an
                                                    incredible line-up of speakers and networking opportunities in store for you. Please
                                                    do join us to make this a memorable and enriching experience.
                                                </Card.Text>
                                            </div>
                                            <div className='col-md-4 mb-3 profile1'>
                                                <Card.Img className='mb-2' variant="top" src={docimg} style={{ height: "170px", width: "150px" }} />
                                                <Card.Text className='m-0'>
                                                    Dr. Ravindra Kumar Dewan
                                                </Card.Text>
                                                <Card.Text>
                                                    Patron
                                                </Card.Text></div>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className='col-lg-12 tip'>

                                <Card className='mt-2 special'>
                                    <Card.Body className='home-card-body'>
                                        <Row>
                                            <Col className='mb-2'>
                                                <Card.Title>Welcome to the STOP HIV 2024, New Delhi
                                                </Card.Title>
                                            </Col>
                                        </Row>
                                        <Row className='colreverse'>
                                            <div className='col-md-8'>
                                                <Card.Subtitle className="mb-2 text-muted">Dear Colleagues and Friends,</Card.Subtitle>
                                                <Card.Text className='text-justify'>
                                                    In association with Dr Ravindra K Dewan, Dr Upasna Agarwal  and the organizing
                                                    committee, it is a pleasure and  honor for me to welcome you to the ‘STOP HIV
                                                    2024” conference to be held 13-14 January, 2024 at the  India Habitat Center, New
                                                    Delhi. The conference brings together an outstanding faculty of international and
                                                    local experts to provide important information on strategies for treatment and
                                                    opportunities for the prevention of HIV. The focus of the meeting will be on
                                                    presenting perspectives that apply to practice. As HIV remains an important
                                                    clinical problem, with ever evolving new information, we feel the information
                                                    from the meeting will be of great interest to practicing clinicians.</Card.Text>
                                            </div>
                                            <div className='col-md-4 mb-3 profile1'>
                                                <Card.Img variant="top" className='mb-2' src={docimg1} style={{ height: "170px", width: "150px" }} />
                                                <Card.Text className='m-0'>
                                                    Prof. Marcus Zervos
                                                </Card.Text>
                                                <Card.Text>
                                                    Organising Chairman
                                                </Card.Text>
                                            </div>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <div className='dr-card01'>   </div>
                            </div>
                        </Row>
                    </Container>

                </div >
            </div >
            <AboutVanue />
            <LocalAttractions />

        </div >
    )
}

export default Home