import React from 'react'
import { Container, Row, Col, Button, } from 'react-bootstrap'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



import cardimg4 from '../../img/cart-bg-one.png'

import cardimg1 from '../../img/teams/Dr.RKDewan.png'
import cardimg2 from '../../img/teams/Marcus Zervos MD.png'
import cardimg3 from '../../img/teams/Dr. Upasna.png'
import cardimg5 from '../../img/teams/NormanMarkowitzMD.png'
import cardimg6 from '../../img/teams/Dr. Rajnish Gupta.png'
import cardimg7 from '../../img/teams/Dr.AshishRanjan.png'
import cardimg8 from '../../img/teams/SmithaGudipati.png'
import cardimg9 from '../../img/teams/DrLokenderKumar.png'
// import cardimg10 from '../../img/teams/DrAnanyaPrabhu.png'
import cardimg11 from '../../img/teams/DrJitendraKumar Saini.png'
import cardimg12 from '../../img/teams/DrAnanyaPrabhu.png'
import cardimg13 from '../../img/teams/Dr.RajeshKumar.png'
import cardimg14 from '../../img/teams/drparvesh.jpg'
const Teams = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1024 },
            items: 3,
            slidesToSlide: 2,
        },
        hass: {
            breakpoint: { max: 1300, min: 1200 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 1200, min: 1000 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1000, min: 770 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 770, min: 550 },
            items: 1,
        },
        mobile2: {
            breakpoint: { max: 550, min: 0 },
            items: 1,
        },
    };


    const details = [{
        id: 0,
        Img: cardimg1,
        name: "Dr. R.K. Dewan",
        title: "Director NITRD",
        btn: "Patron"
    }, {
        id: 1,
        Img: cardimg2,
        name: "Prof. Marcus Zervos",
        title: `Division Head, Infectious Diseases`,
        btn: "Organizing Chairman"
    }, {
        id: 2,
        Img: cardimg3,
        name: "Dr.Upasna Agarwal",
        title: "Head,Department of Medicine (NITRD)",
        btn: "Organizing Secretary"
    }, {
        id: 3,
        Img: cardimg5,
        name: "Prof. Norman Markowitz",
        title: "Infectious Diseases Specialist",
        btn: "Chairman Scientific Committee"
    }, {
        id: 4,
        Img: cardimg6,
        name: "Dr. Rajnish Gupta",
        title: "Specialist TB & RD (NITRD)",
        btn: "Co- chairman Scientific Committee"
    }, {
        id: 5,
        Img: cardimg7,
        name: "Dr. Ashish Ranjan",
        title: "Specialist TB & RD (NITRD)",
        btn: "Dy Organizing Secretary"
    }, {
        id: 6,
        Img: cardimg8,
        name: "Dr. Smitha Gudipati ",
        title: "Infectious Diseases Specialist at Henry Ford Health",
        btn: "Dy Organizing Secretary"
    }, {
        id: 7,
        Img: cardimg9,
        name: "Dr. Lokender Kumar",
        title: "Specialist TB & RD (NITRD)",
        btn: "Treasurer"
    }, {
        id: 8,
        Img: cardimg11,
        name: "Dr J K saini",
        title: "Specialist TB & RD (NITRD)",
        btn: "Treasurer"
    }, {
        id: 9,
        Img: cardimg12,
        name: "Dr.Ananya Prabhu",
        title: "Specialist TB & RD (NITRD)",
        btn: "Coordinator (Logistics)"
    }, {
        id: 10,
        Img: cardimg13,
        name: "Dr.Rajesh Kumar",
        title: "Chief Medical Officer, (NITRD)",
        btn: "Venue Co-ordinator"
    }, {
        id: 11,
        Img: cardimg14,
        name: "Dr.Pravesh Yadav",
        title: "AMS, (NITRD)",
        btn: "AMS"
    },]
    return (
        <Container>
            <Row className='teammain'>
                <Col className='d-flex align-center justify-content-center' >
                    <h1 id='teams'>The Organising Team</h1>
                </Col>
            </Row>

            <Row className=' main-row' >
                <Carousel responsive={responsive}>
                    {details.map((item) => {
                        return (<Col key={item.id} className='mb-5' >
                            <div className="card-header">
                                {/* <img className='img-thumbnail card-bg' src={cardimg4} alt='' /> */}
                                <div className='team-card-body'>
                                    <div className='team-card-img-div'>
                                        <img className='team-card-img' src={item.Img} alt='' />
                                    </div>
                                    <div className='team-card-content-div mt-3 p-3'>
                                        <span className='span1'>{item.name}</span>
                                        <span className='span2'>{item.title}</span>
                                        <Button className='team-card-btn' variant=''>{item.btn}</Button>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        )
                    })}
                </Carousel>
            </Row>

        </Container>
    )
}

export default Teams