const Registration = [{
    id: 0,
    titile: "Dr. P. Sethi – Chairperson",
}, {
    id: 1,
    titile: "Dr. Saroj Kumar Meena",

},
{
    id: 2,
    titile: "Dr. Paras Singh",

}, {
    id: 3,
    titile: "Dr. Alok Yadav",
}, {
    id: 4,
    titile: "Dr. Amit Sharma",
}, {
    id: 5,
    titile: "Dr. Reshma (JR) / Dr. Kiran (JR)",
}, {
    id: 6,
    titile: "Ms. Bhagwati (ART Counsellor)",
}]


const Venue = [{
    id: 0,
    titile: "Dr. Ritu Singhal– Chairperson",
}, {
    id: 1,
    titile: "Dr. Ashish Ranjan",

},
{
    id: 2,
    titile: "Dr. AnanyaParbhu",

}, {
    id: 3,
    titile: "Dr. B. Rama Phanindra Kumar",
}, {
    id: 4,
    titile: "Dr. Saraansh Bansal",
},
{
    id: 5,
    titile: "Dr (Ms.) Ankita Day",
}, {
    id: 6,
    titile: "Dr. Vishwadeep (JR)",
}, {
    id: 7,
    titile: "Dr. Shashank (JR)",
}, {
    id: 8,
    titile: "Dr. Pooja (ART Medical Officer)",
}]


const Inauguration = [{
    id: 0,
    titile: "Dr. J. K. Saini – Chairperson",
}, {
    id: 1,
    titile: "Dr. P. Sethi",

},
{
    id: 2,
    titile: "Dr. Devika Tayal",

}, {
    id: 3,
    titile: "Dr. (Mrs.) Ankita Day",
},
{
    id: 4,
    titile: "Dr. Shivani (JR)",
}, {
    id: 5,
    titile: "Ms. Tulsi (ART Data Manager)",
}]


const AudioVisual = [{
    id: 0,
    titile: "Dr. Devesh Chauhan – Chairperson",
}, {
    id: 1,
    titile: "Dr. Puneet Aurora",

},
{
    id: 2,
    titile: "Dr. Shalini Mulik",

}, {
    id: 3,
    titile: "Dr.Saroj Kumar Meena",
}, {
    id: 4,
    titile: "Dr. Gokul (JR)",
},
{
    id: 5,
    titile: "Mr. Rajeev Senger",
}, {
    id: 6,
    titile: "Mr. Arjun (ART Data Manager)",
}]


const Bags = [{
    id: 0,
    titile: "Dr. Ananya Prabhu – Chairperson",
}, {
    id: 1,
    titile: "Dr. Rajesh Kumar",

},
{
    id: 2,
    titile: "Dr. Paras Singh",

}, {
    id: 3,
    titile: "Ms. Parveen (ART Counsellor)",
},
{
    id: 4,
    titile: "Dr. Yasir (SR)",
}]


const Banners = [{
    id: 0,
    titile: "Dr. B. Rama Phanindra Kumar– Chairperson",
}, {
    id: 1,
    titile: "Dr. Saraansh Bansal- Co Chairperson",

}, {
    id: 2,
    titile: "Dr. Meet Ghonia (JR)/ Dr. Meet (JR)",
}, {
    id: 3,
    titile: "DMr. Neeraj Dubey (ART Counsellor)r. Amit Sharma",
},
]


const Transport = [{
    id: 0,
    titile: "Dr. Lokender Kumar – Chairperson",
}, {
    id: 1,
    titile: "Dr. Alok Yadav",

},
{
    id: 2,
    titile: "Mr. Devraj (ART-LT)",

},
]





const Stationary = [{
    id: 0,
    titile: "Dr. B. Rama Phanindra Kumar– Chairperson",
}, {
    id: 1,
    titile: "Dr. Saraansh Bansal- Co Chairperson",

}, {
    id: 2,
    titile: "Dr. Meet Ghonia (JR)/ Dr. Meet (JR)",
}, {
    id: 3,
    titile: "DMr. Neeraj Dubey (ART Counsellor)r. Amit Sharma",
},
]

const Accommodation = [{
    id: 0,
    titile: "Dr. Pravesh Yadav– Chairperson",
}, {
    id: 1,
    titile: "Dr. Ashish Ranjan",

},
{
    id: 2,
    titile: "Dr. Rajesh Kumar",

}, {
    id: 3,
    titile: "Dr. (Mrs.) Anita Rani Kansal",
}, {
    id: 4,
    titile: "Mr. Rahul Joshi, A. E. (C)",
},
{
    id: 5,
    titile: "Dr. Vivek, J. E. (Elect)",
},
]


const Floral = [{
    id: 0,
    titile: "Dr. Saroj Kumar Meena– Chairperson",
}, {
    id: 1,
    titile: "Dr. (Mrs.) Anita Rani Kansal",

},
{
    id: 2,
    titile: "Ms. Sarika (ART)",
}
]



const Food = [{
    id: 0,
    titile: "Dr. Saroj Kumar Meena– Chairperson",
}, {
    id: 1,
    titile: "Dr. Prem (JR)",

},
{
    id: 2,
    titile: "Dr. Meet Ghonia (JR)",

}, {
    id: 3,
    titile: "Dr. Dhruvil (JR",
}, {
    id: 4,
    titile: "Dr. Prithvirajan (JR)",
},
]

const Fund = [{
    id: 0,
    titile: "Dr. Ajoy Kumar Verma – Chairperson",
}, {
    id: 1,
    titile: "Dr. Saroj Kumar Meena",

},
{
    id: 2,
    titile: "Dr. Rajesh Kumar",

},
]



export { Registration, Venue, Inauguration, AudioVisual, Bags, Banners, Transport, Stationary, Food, Floral, Accommodation, Fund }