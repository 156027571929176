import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import img from '../../img/ab12.png'
import img2 from '../../img/Henry Ford Hospital Exterior Image Desktop.jpg'
import img3 from '../../img/nitrd.webp'
const AboutImg = () => {
    return (
        <>
            <Container fluid className='p-0'>
                <img src={img} alt='' className='main-container-img' ></img>
            </Container>
            <Container fluid className='mt-4'>
                <Row className='' id='nitrd'>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row className='row-img row-img-2 nitrd-flex1'>
                                    <Col className='img-col img-col-2'>
                                        <Card.Img src={img3} alt=''></Card.Img>
                                    </Col>
                                    <Col className='cards-details'>
                                        <Card.Subtitle className='mt-3'>About</Card.Subtitle>
                                        <Card.Title className='about-title mt-2'>NITRD</Card.Title>
                                        <Card.Text className='text-justify'>National Institute of TB & respiratory Diseases, New Delhi, India is an institute of national recognition
                                            in field of tuberculosis &amp; HIV, pulmonary medicine, thoracic surgery and other related fields. The
                                            institute is involved in providing the best possible clinical care as well as postgraduate teaching in
                                            Thoracic Surgery, Pulmonary Medicine, and Microbiology. The institute has been pioneer in training
                                            and research activities since its inception. The institute offers high quality researches in related fields
                                            through mutual collaborations and</Card.Text>
                                    </Col>


                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr/>
                <Row id='Henryford' className='mt-2 '>
                    <Col>
                        <Card className='mb-3 '>
                            <Card.Body>
                                <Row className='row-img nitrd-flex1-hen'>
                                    <Col className='cards-details'>
                                        <Card.Subtitle className='mt-3'>About </Card.Subtitle>
                                        <Card.Title className='about-title mt-2'>Henry Ford Hospital</Card.Title>

                                        <Card.Text className='text-justify'>Henry Ford Hospital, Detroit, USA is a state of the art academic hospital and research center with bed
                                            strength of 877. It is recognized for clinical excellence in various medical &amp; surgical specialties. It has
                                            been a leader for over 100 years in advancing medicine and delivering the most innovative treatments.
                                            Henry Ford Hospital provides specialized healthcare facilities to people from throughout the United
                                            States and the world over. It is the flag bearer of continuous professional development programs and
                                            research &amp; innovations in various fields.</Card.Text>
                                    </Col>
                                    <Col className='img-col'>
                                        <Card.Img alt='' src={img2}></Card.Img>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default AboutImg