import React from 'react';
import './runner.css';
import { Link } from 'react-router-dom';

const Runner = () => {
    return (
        <div>
            <div className="runner-container scroll-right">
                <span>New updates</span>
                <marquee className="marq" behavior="" direction="left" scrollamount="5" scrolldelay="10">
                    1. Scientific programme has been uploaded. Please see the "Scientific programme"
                    2. Registration is closed now. For any query please Email us to <Link style={{ textDecoration: "none" }} to="mailto:stophiv2024@gmail.com">stophiv2024@gmail.com </Link>
                </marquee>
            </div>
        </div>
    );
}

export default Runner;
