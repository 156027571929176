import { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
function Example() {

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const form = useRef()
    const [detail, setdetail] = useState({
        name: "",
        email: "",
        message: ""
    })

    const onChangehandler = (e) => {
        const { name, value } = e.target;
        setdetail({
            ...detail, [name]: value
        })

    }
    const [count, setcount] = useState(0)

    const counthandler = () => {
        setcount(detail.message.length)
    }

    useEffect(() => {
        counthandler()
    })

    const onSubmitdata = async (e) => {

        e.preventDefault();
        const { name, email, message } = detail
        try {
            if (email === " " || name === "" || message === "") {
                return toast.error("Please fill all fields")
            }
            else {
                if (message.length > 250) {
                    return toast.warning('Message should be less than or equal to 250 characters')
                }
                else {
                    handleClose()
                    const response = await axios.post(`${backendUrl}/sendabstractemail`, detail);
                    setdetail({
                        name: "",
                        email: "",
                        message: ""
                    })
                    if (response) {
                        // console.log(response);
                        toast.success(response.data.message)
                    } else {
                        toast.error(response.data.message)
                        // throw new Error('Error in sending mail');
                    }
                }
            }
        } catch (error) {
            console.log("error")
        }

    }





    return (
        <>
            <ToastContainer />
            <Button variant="primary" className='abstract-btn' onClick={handleShow}>
                Click Here To Submit Abstract
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header className='d-flex justify-content-between'>
                    {/* <Modal.Title className='text-center w-100'>
                        Stop HIV 2024
                    </Modal.Title> */}
                    <button className='btn-close' onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form ref={form} onSubmit={onSubmitdata}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput0">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Mr. Abc Vrema"
                                autoFocus
                                name="name"
                                value={detail.name}
                                onChange={onChangehandler}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                autoFocus
                                name="email"
                                value={detail.email}
                                onChange={onChangehandler}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label >Write Abstract and it should be less then 250</Form.Label>
                            <Form.Text ><div className='mb-2' onChange={counthandler}>Length:{count}</div></Form.Text>
                            <Form.Control
                                className="countlength"
                                name="message"
                                value={detail.message}
                                onChange={onChangehandler}
                                as="textarea"
                                rows={3} />
                        </Form.Group>
                        <Form.Group className='text-center'>
                            <Button type='submit'>
                                Submit Abstract
                            </Button>
                        </Form.Group>
                    </Form> */}
                    <div className='row'>
                        <div className='col-sm-12'>
                            <ol>
                                <li>
                                    Scientific programme has been uploaded. Please see the "Scientific programme"
                                </li>
                                <li>
                                    Registration is closed now. For any query please Email us to <Link style={{ textDecoration: "none" }} to="mailto:stophiv2024@gmail.com">stophiv2024@gmail.com </Link>
                                </li>
                            </ol>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={handleClose}>
                        close
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Example;