import React from 'react'
import logo1 from '../../../img/logo1.png'
import logo2 from '../../../img/logo2.png'
import logo3 from '../../../img/henery-ford.png'
import './style.css'
import { Container } from '@mui/material'
import { Row } from 'react-bootstrap'
const CenterHead = () => {
    return (
        <div className='center-nav'>
            <Container>
                <Row className='main-row'>
                    <div className='col-lg-2 col-md-2'>
                        <div className='img-box' ><img className='img1 img-fluid' src={logo1} alt='' /></div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className=''>
                            <p className='topheading2'>PRESENTING PERSPECTIVES FOR PRACTICE</p>
                            <p className='topheading1'><span className='stop-hiv'>STOP HIV 2024</span></p>
                            <p className='topheading3'>Strategies For Treatment And Opportunities For Prevention Of HIV</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <div className='img-box-img'>
                            <img className="img2 img-fluid" src={logo2} alt='' />
                            <img className="img2 img-fluid" src={logo3} alt='' />
                        </div>
                    </div>
                </Row>
                {/* <Row className='main-row'>
                    <div className='col-lg-2 '>
                        <div className='img-box' ><img className='img1 img-fluid' src={logo1} alt='' /></div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className=''>
                            <p className='topheading1'>STOP HIV 2024</p>
                            <p className='topheading2'>Presenting perspectives for practice</p>
                            <p className='topheading3'>Strategies for Treatment and oppourtunities for Prevention of HIV</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='img-box-img '>
                            <img className="img2 img-fluid" src={logo2} alt='' />
                            <img className="img2 img-fluid" src={logo3} alt='' />
                        </div>
                    </div>
                </Row> */}
                {/* <div className='container-fluid'>
                <div className='row'>

                    <div className='col'><img className='img1 img2' src={logo1} alt='' /></div>
                    <div className='col'>
                        <p className='topheading'>Presenting perspectipracticeves for</p>
                        <p className='topheading2'>Strategies for Treatment and oppourtunities for Prevention of HIV</p>
                        <p className='topheading3'>STOP HIV 2024</p>
                    </div>
                    <div className='col'>
                        <img className="img1 img2" src={logo2} alt='' />
                    </div>
                    <div className='col'>
                        <img className="img1 img2" src={logo3} alt='' />
                    </div>
                </div>
            </div > */}
            </Container>
        </div>

    )
}

export default CenterHead;