import React from 'react'
import { CardImg, Col, Container, Row } from 'react-bootstrap'
import vanueimg from "../../img/india-habitat-centre.jpg"
import GoogleMapEmbed from '../../components/map'
const AboutVanue = () => {
    return (
        <Container fluid className='Aboutvanue' style={{ background: "rgb(249 242 234)" }}>
            <Row className=''>
                <Col className="attraction-col1 text-center"><h1>About Venue</h1></Col>
            </Row>
            <Row className=' box2'>
                <Col><p className='about-text1'>Spread over an area of nine acres, the India Habitat Centre is an island of architectural
                    excellence in the busy metropolis of India's capital, New Delhi. The campus weaves in a unique interplay of institutions,
                    supporting infrastructure and facilities such as conference venues, auditoria, hospitality areas, the library and resource
                    centre, and art galleries. Elegantly designed, the campus is a hub of activity while simultaneously being serene. The
                    campus is wi-fi enabled. The IHC is located at Lodhi Road, in the heart of Lutyens Delhi. and has been a witness to plethora of academic activities.</p></Col>
                <Col>
                    <CardImg src={vanueimg} />
                </Col>
            </Row>
            <Row className="map1">
                <GoogleMapEmbed   />
            </Row>
        </Container>

    )
}

export default AboutVanue