
export const gudelines =
    [
        {
            id: 0,
            title: `Abstract has to be submitted on website link (https://www.stophiv2024.com/) or on email
stophiv2024@gmail.com.`
        },
        {
            id: 1,
            title: `The last date for abstract submission is 10 th December, 2023.`
        },
        {
            id: 2,
            title: `First Author has to present the abstract himself/herself.`
        }]
