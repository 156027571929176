import React from "react";
import './style.css'
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
function TopHeader() {


    return (
        <>

            <Container fluid className="header">
                {/* <Row>
                    <Col style={{ borderRight: "1px solid black" }}>
                        <Link to="mailto:stophiv2024@gmail.com" className="mainlinks"><EmailIcon className="icon" />stophiv2024@gmail.com</Link>
                    </Col>
                    <Col>
                        <span><LocalPhoneIcon className="icon" />+91 9650683653</span>
                    </Col>
                </Row> */}
                <div className="row">

                    <div className="col-md-6">
                        {/* <p >open@gmil.com</p> */}

                        <p className="open-text "><Link className="mainlinks" to="mailto:stophiv2024@gmail.com" ><EmailIcon className="icon" /> &nbsp; stophiv2024@gmail.com</Link></p></div>
                    <div className="col-md-6"> <p className="open-text1"><LocalPhoneIcon className="icon" /> &nbsp; +91 8800788930</p> </div>
                </div>
            </Container>
        </>
    );
}

export default TopHeader;