import React from 'react'
import { Card, Col, Container, Row, } from 'react-bootstrap'
import './style.css';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMapEmbed from '../../components/map';
const ContactUs = () => {



    return (
        <div className='contact-maindiv' >
            <Container >
                <Row className='contact-row p-4'>
                    <Col className='col-lg-6 conf-bordre1'>
                        <Card className='contact-card mt-3 mb-2'>
                            <Card.Body className='contact-card-body'>
                                <Card.Title><h1>Get In Touch!</h1></Card.Title>
                                {/* <Card.Text>
                                    Fill up the form and our Team will get back to you within 24 hours.
                                </Card.Text> */}
                                <Card.Text className=' contact-text location-text' ><LocationOnIcon />  <span> &nbsp;&nbsp;Delhi</span></Card.Text>
                                <Card.Text className='contact-text location-text' >
                                    <LocalPhoneIcon />&nbsp;&nbsp;+91 8800788930
                                </Card.Text>
                                <Card.Text className='contact-text location-text' >
                                    <LocalPhoneIcon />&nbsp;&nbsp;+91 8076387090
                                </Card.Text>
                                <Card.Text className='contact-text' ><EmailIcon /> &nbsp;&nbsp;stophiv2024@gmail.com</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col-lg-6 col-md-12 col-sm-12 col-12'>
                        <GoogleMapEmbed />

                    </Col>
                </Row>
            </Container >
        </div>

    )
}

export default ContactUs