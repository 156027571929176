import React from 'react'
import page from '../img/404.png'
import './notfound.css'
import { Link } from 'react-router-dom'

const PagenotFound = () => {
    return (
        <div className='container-fluid'>
            <div className='container pagenotfound commingmainimg'>
                <img className='img-fluid' src={page} alt='' />
                <Link className='mainlink' to="/">Go to Home</Link>
            </div>
        </div>
    )
}

export default PagenotFound