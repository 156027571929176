import { useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./style.css";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo1 from '../../../img/logo1.png'
import MyModal from "../../popupmodel/PopupModel";
import PaymentModes from "../../../pages/registration/PaymentModes";
import braucher from '../../../img/Tentativescientificprogram.pdf'
import { Outlet } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import braucher1 from '../../../img/ScientificProgram.pdf'
function Header() {
    const navigate = useNavigate();
    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle(
            "responsive_nav"
        );
    };


    const [modalShow, setModalShow] = useState(false);
    const closeModal = () => {
        setModalShow(false)
    }
    const [paymentmodalShow, setpaymentmodalShow] = useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        // console.log(section);
    };


    return (
        <>
            <header>
                <h1>
                    <img className="header-logo mt-2" src={logo1} alt="" />
                </h1>
                <nav ref={navRef}>
                    <Nav.Item className="link">
                        <Nav.Link onClick={() => {
                            navigate("/");
                            showNavbar()
                        }}>Home</Nav.Link>
                    </Nav.Item>
                    <NavDropdown title="About Us" id="nav-dropdown"
                        onClick={() => {
                            navigate("/about");
                        }}>
                        <NavDropdown.Item eventKey="4.1" onClick={() => {
                            showNavbar()
                            scrollToSection("nitrd")
                        }} >NITRD</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2" onClick={() => {

                            showNavbar()
                            scrollToSection("Henryford")
                        }}> Henry Ford </NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2" onClick={() => {
                            showNavbar()
                            scrollToSection("teams")
                        }}>Teams</NavDropdown.Item>
                    </NavDropdown>


                    <NavDropdown title="Committees" id="nav-dropdown" onClick={() => {
                        navigate("/committee")
                        showNavbar()

                    }}>
                        <NavDropdown.Item eventKey="4.1" onClick={() => {
                            // navigate("/committee");
                            showNavbar()
                            scrollToSection("OrganisingCommittee")
                        }}>Organising Committee</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2"
                            onClick={() => {
                                // navigate("/committee");
                                showNavbar()
                                scrollToSection("ScientificCommittee")
                            }}
                        >Scientific Committee</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2"
                            onClick={() => {
                                showNavbar()
                                scrollToSection("Scientificadvisors")
                            }}
                        >Scientific Advisors</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2"
                            onClick={() => {
                                showNavbar()
                                scrollToSection("otherssection")
                            }}
                        >Other Committee </NavDropdown.Item>
                    </NavDropdown>


                    <NavDropdown title="Registration" id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.1" onClick={() => {
                            setpaymentmodalShow(true)

                        }}>Payment Modes</NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2" onClick={() => {
                            setModalShow(true)
                            showNavbar()
                        }
                        }>Register</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item className="link">
                        <Nav.Link onClick={() => {
                            navigate("/scientific")
                            showNavbar()
                        }}
                            href={braucher1}
                            target="_blank"
                        >Scientific Programme</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="link">
                        <Nav.Link href={braucher} target="_blank">Scientific Programme</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className="link">
                        <Nav.Link onClick={() => {
                            navigate("/abstract");
                            showNavbar()
                        }}> Abstract</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="link">
                        <Nav.Link onClick={() => {
                            navigate("/Contactus");
                            showNavbar()
                        }}>Contact Us</Nav.Link>
                    </Nav.Item>
                    <button
                        className="nav-btn nav-close-btn"
                        onClick={showNavbar}>
                        <FaTimes />
                    </button>
                </nav>
                <button
                    className="nav-btn"
                    onClick={showNavbar}>
                    <FaBars />
                </button>
            </header >
            <div className="registerform">
            </div>
            <div className="form-box">
                <MyModal
                    show={modalShow}
                    onHide={closeModal}
                />
                <PaymentModes
                    show={paymentmodalShow}
                    onHide={() => setpaymentmodalShow(false)}
                />

            </div>
            <div>
                <Outlet />
            </div>
        </>
    );
}

export default Header;