const organising = [{
    id: 0,
    titile: "Dr. Ravindra Kumar Dewan,",
    subtitle: "Director NITRD"
}, {
    id: 1,
    titile: "Prof. Marcus Zervos",
    subtitle: `Division Head, Infectious Diseases, Henry Ford Hospital.
    Professor of Medicine, College of Human Medicine, Michigan State University.`
},
{
    id: 2,
    titile: "Dr. Upasna Agarwal,",
    subtitle: "Head, Department of Medicine (NITRD)"
}, {
    id: 3,
    titile: "Dr. Ashish Ranjan",
    subtitle: "Specialist TB & RD (NITRD)"
}, {
    id: 4,
    titile: "Dr. Smitha Gudipati,",
    subtitle: "Infectious Diseases Specialist at Henry Ford Health"
},
{
    id: 5,
    titile: "Prof. Norman Markowitz",
    subtitle: "Infectious Diseases Specialist, Henry Ford  Health"
},
{
    id: 6,
    titile: "Dr. Rajnish Gupta,",
    subtitle: "Specialist TB & RD (NITRD)"
},
{
    id: 7,
    titile: "Dr. Lokender Kumar",
    subtitle: "Specialist TB & RD (NITRD)"
},
{
    id: 8,
    titile: "Dr. JK Saini",
    subtitle: "Specialist TB & RD (NITRD)"
}, {
    id: 9,
    titile: "Dr.Ananya Prabhu",
    subtitle: "Specialist TB & RD (NITRD)"
}, {
    id: 10,
    titile: "Dr. Rajesh Kumar",
    subtitle: "Chief Medical Officer,(NITRD),"
}]
export default organising;