import React from 'react'
import AboutImg from './AboutImg'
import './style.css'
import Teams from './Teams'

const About = () => {
    return (
        <div style={{ background: "" }} >
            <AboutImg />
            <Teams />

        </div>
    )
}

export default About