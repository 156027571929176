import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
import organising from './organising'
import scientific from './scientific'
import advisary from './advisory'
import { Registration, Venue, Inauguration, AudioVisual, Bags, Banners, Transport, Stationary, Accommodation, Floral, Food, Fund } from './others'


const Committee = () => {



    return (
        <Container fluid className='main-container1'>
            <Container>
                <section >
                    <Row>
                        <Col className='text-center'>
                            <h1 className='mt-5 mb-3' id='OrganisingCommittee'>Organising Committee</h1>
                        </Col>
                    </Row>
                    <Row className=''>
                        {organising.map((item) => {
                            return <Col key={item.id} className='text-center   col-xl-3  col-lg-4  col-md-6 col-sm-6 col-12'>
                                <div className='committe-col organising '>
                                    <h5 className='col-text-color'>
                                        {item.titile}
                                    </h5>
                                    <span className='col-text-color2'>{item.subtitle}</span>
                                </div>
                            </Col>

                        })}
                    </Row>
                </section>
                <section >
                    <Row >
                        <Col className='text-center'>
                            <h1 className='mt-5 mb-3 ' id='ScientificCommittee'>Scientific Committee</h1>
                        </Col>
                    </Row>
                    <Row >
                        {scientific.map((item) => {
                            return <Col key={item.id} className=' text-center   col-xl-3  col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='committe-col Scientific'>
                                    <h5 className='col-text-color' >
                                        {item.titile}
                                    </h5>
                                </div>
                            </Col>

                        })}
                    </Row>
                </section>
                <section >

                    <Row>
                        <Col className='text-center'>
                            <h1 className='mt-5 mb-3' id='Scientificadvisors'>Scientific Advisors</h1>
                        </Col>
                    </Row>
                    <Row >
                        {advisary.map((item) => {
                            return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6 col-12'>
                                <div className='committe-col advisary'>

                                    <h5 className='col-text-color'>
                                        {item.titile}
                                    </h5>
                                </div>
                            </Col>

                        })}
                    </Row>
                </section>
                <section >
                    <section>

                        <Row >
                            <Col className='text-center'>
                                <h1 className='mt-5 mb-3' id='otherssection'>Others Committees</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col><h2>Registration Committee:</h2></Col>

                        </Row>
                        <Row>
                            {Registration.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6  col-12'>
                                    <div className='committe-col advisary'>
                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </section>
                    <section>

                        <Row>
                            <Col><h2 className='othersheading'>Venue Management:</h2></Col>
                        </Row>
                        <Row>
                            {Venue.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6  col-12'>
                                    <div className='committe-col advisary'>

                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </section>

                    <Row>
                        <Col><h2 className='othersheading'>Inauguration &amp; Valedictory:</h2></Col>

                    </Row>
                    <Row>
                        {Inauguration.map((item) => {
                            return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6 col-12'>
                                <div className='committe-col advisary'>

                                    <h5 className='col-text-color'>
                                        {item.titile}
                                    </h5>
                                </div>
                            </Col>

                        })}
                    </Row>
                    <Row>
                        <Col><h2 className='othersheading'>Audio Visual, Wifi&amp; Video Conferencing:</h2></Col>

                    </Row>
                    <Row>
                        {AudioVisual.map((item) => {
                            return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6  col-12'>
                                <div className='committe-col advisary'>

                                    <h5 className='col-text-color'>
                                        {item.titile}
                                    </h5>
                                </div>
                            </Col>

                        })}
                    </Row>
                    <section>

                        <Row>
                            <Col><h2 className='othersheading'>Bags &amp; Mementos:</h2></Col>

                        </Row>
                        <Row>
                            {Bags.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6  col-12'>
                                    <div className='committe-col advisary'>

                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </section>

                    <Row>
                        <Col><h2 className='othersheading'>Banners &amp; Back Drop Committee:</h2></Col>

                    </Row>
                    <Row>
                        {Banners.map((item) => {
                            return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6  col-12'>
                                <div className='committe-col advisary'>

                                    <h5 className='col-text-color'>
                                        {item.titile}
                                    </h5>
                                </div>
                            </Col>
                        })}
                    </Row>
                    <section>

                        <Row>
                            <Col><h2 className='othersheading'>Transport Committee:</h2></Col>

                        </Row>
                        <Row>
                            {Transport.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6 col-12'>
                                    <div className='committe-col advisary'>

                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </section>
                    <section>

                        <Row>
                            <Col><h2 className='othersheading'>Stationary &amp; Printing:</h2></Col>

                        </Row>
                        <Row>
                            {Stationary.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6 col-12'>
                                    <div className='committe-col advisary'>

                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </section>
                    <section>


                        <Row>
                            <Col><h2 className='othersheading'>Accommodation Committee:</h2></Col>

                        </Row>
                        <Row>
                            {Accommodation.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6 col-12'>
                                    <div className='committe-col advisary'>

                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>

                            })}
                        </Row>
                    </section>
                    <section>

                        <Row>
                            <Col><h2 className='othersheading'>Floral Decoration:</h2></Col>

                        </Row>
                        <Row>
                            {Floral.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6  col-12'>
                                    <div className='committe-col advisary'>

                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>

                            })}
                        </Row>
                    </section>
                    <section>

                        <Row>
                            <Col><h2 className='othersheading'>Food Committee:</h2></Col>

                        </Row>
                        <Row>
                            {Food.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6 col-12'>
                                    <div className='committe-col advisary'>

                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </section>
                    <section>

                        <Row>
                            <Col><h2 className='othersheading'>Fund Raising Committee:</h2></Col>

                        </Row>
                        <Row>
                            {Fund.map((item) => {
                                return <Col key={item.id} className=' text-center col-xl-3  col-lg-4 col-md-6 col-12'>
                                    <div className='committe-col advisary'>
                                        <h5 className='col-text-color'>
                                            {item.titile}
                                        </h5>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </section>
                </section>

            </Container>

        </Container >
    )
}

export default Committee