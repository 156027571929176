import React from 'react';

const GoogleMapEmbed = () => {
    return (
        <div className="mapouter">
            <div className="gmap_canvas">
                <iframe
                    title="india habitate center map"
                    className="gmap_iframe"
                    width="100%"
                    height="350"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=india habitat center&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
            </div>
        </div>
    );
};

export default GoogleMapEmbed;
