import { React, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const MyModal = (props) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    // console.log(backendUrl);
    const form = useRef();
    const [user, setUser] = useState({
        name: '',
        destination: '',
        phone: '',
        email: '',
        refId: ''
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user, [name]: value
        })
    }
    const register = async (e) => {
        e.preventDefault()
        const { name, email, destination, phone, refId } = user
        props.onHide();

        // console.log(user);
        try {
            if (name === "" || email === "" || destination === "" || phone === "" || refId === "") {
                toast.error("Please fill all fields")
            } else {
                const response = await axios.post(`${backendUrl}/sendemail`, user);
                setUser({
                    name: '',
                    destination: '',
                    phone: '',
                    email: '',
                    refId: ''
                })
                if (response) {
                    // console.log(response);
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                    // throw new Error('Error in sending mail');
                }

            }

        } catch (error) {
            console.log("error")
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className='d-flex justify-content-between'>
                    {/* <Modal.Title className='text-center w-100'>
                        Registration Form<br></br>
                        <p className='text-muted mb-0'>Still Online after making payment</p>
                    </Modal.Title> */}
                    <button className='btn-close' onClick={props.onHide}></button>
                </Modal.Header>
                <Modal.Body>

                    {/* <Form ref={form} onSubmit={register} >
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Name :</Form.Label>
                            <Form.Control type="text" name='name' value={user.name} onChange={handleChange} placeholder="Enter Your Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicDestination">
                            <Form.Label>Designation: </Form.Label>
                            <Form.Control type="text" name='destination' value={user.destination} onChange={handleChange} placeholder="Enter Your Designation" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicNumber">
                            <Form.Label>Mobile no: </Form.Label>
                            <Form.Control type="number" name='phone' value={user.phone} onChange={handleChange} placeholder="Enter your Number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name='email' value={user.email} onChange={handleChange} placeholder="Enter email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicrefferenceid">
                            <Form.Label>Payment reference No./UTR No.</Form.Label>
                            <Form.Control type="text" name='refId' value={user.refId} onChange={handleChange} placeholder="Enter your refrence no for payment" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form> */}
                    <div className='row'>
                        <div className='col-sm-12'>
                            <ol>
                                <li>
                                    Scientific programme has been uploaded. Please see the "Scientific programme"
                                </li>
                                <li>
                                    Registration is closed now. For any query please Email us to <Link style={{ textDecoration: "none" }} to="mailto:stophiv2024@gmail.com">stophiv2024@gmail.com </Link>
                                </li>
                            </ol>
                        </div>

                    </div>
                </Modal.Body >
                <Modal.Footer>
                </Modal.Footer>
            </Modal >
        </>

    );
}

export default MyModal;
